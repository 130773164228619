<script setup lang="ts">
import { useFocusWithin } from "@vueuse/core";
import { onKeyStroke } from "@vueuse/core";
const s = useSekouQuery();

const kw = ref(s.value?.kw?.length > 0 ? s.value.kw.join(" ") : "");

// sが更新された場合、kwも更新する
watch(
  () => s.value,
  (value) => {
    kw.value = value.kw ? value.kw.join(" ") : "";
  },
  {
    deep: true,
  },
);

// kwが無い場合、submitさせない
function onSubmit(e: Event) {
  if (!kw.value) {
    alert("検索したいキーワードを入力してください。");
    e.preventDefault();
  }
}

// 人気のキーワード
const { data: popularWords } = await useFetch(
  "/api/sekou/search/recommend_keyword",
);

// 入力サジェスト関連
const suggest = ref<string[]>([]);

// 検索フォーム入力欄（人気のキーワードを除く）
const searchRef = ref<HTMLElement | null>(null);

// 検索候補一覧
const suggestRef = ref<HTMLElement | null>(null);

// フォーカス状態を取得
const { focused: formFocused } = useFocusWithin(searchRef);
const { focused: suggestFocused } = useFocusWithin(suggestRef);

// フォーカス時にkwが入力されている場合候補を検索
watch(
  () => formFocused.value,
  async () => {
    // フォーカスされた時、キーワードがあって、サジェストが空
    if (formFocused.value && kw.value && !suggest.value.length) {
      suggest.value = await getSuggest();
    }
  },
);

// kw入力されたら候補を検索
watchDebounced(
  () => kw.value,
  async () => {
    suggest.value = await getSuggest();
  },
  { debounce: 300 },
);

// サジェスト取得
async function getSuggest() {
  console.log(`get getSuggest ${kw.value}`);
  // キーワードがない場合空をすぐ返す
  if (!kw.value) return [];
  // キーワードが入力されている場合マッチするか検索
  const { data } = await useFetch(
    `/api/sekou/search/suggest?keyword=${kw.value}`,
  );

  if (data.value && data.value.length) {
    return data.value;
  }

  return [];
}

// 検索欄外をクリックした時候補を初期化
// (iOSの場合v-ifの条件にformFocusedを使うとリンクをクリックしても遷移しないためsuggestの有り無しのみで表示を制御する)
onClickOutside(searchRef, () => {
  suggest.value = [];
});

// キー操作での候補選択
const focusTargetRefs = ref<ComponentPublicInstance[]>([]);
const currentSuggest = ref(0);

// キーワード候補にフォーカスする
function focusSuggest(index: number) {
  const target = focusTargetRefs.value[index];
  target.$el.focus();
}

// 十字キーでのフォーカス切り替え
onKeyStroke("ArrowDown", (e) => {
  e.preventDefault();
  if (!formFocused.value || !focusTargetRefs.value.length) return;
  // 一覧外から入るときは最初の要素にフォーカス
  if (!suggestFocused.value) {
    focusSuggest(0);
    return;
  }

  // フォーカスする要素を次のものに
  if (focusTargetRefs.value.length <= currentSuggest.value + 1) {
    currentSuggest.value = 0;
  } else {
    currentSuggest.value += 1;
  }

  focusSuggest(currentSuggest.value);
});

onKeyStroke("ArrowUp", (e) => {
  e.preventDefault();
  if (!formFocused.value || !focusTargetRefs.value.length) return;
  // 一覧外から入るときは最初の要素にフォーカス
  if (!suggestFocused.value) {
    focusSuggest(0);
    return;
  }

  // フォーカスする要素を前のものに
  if (currentSuggest.value - 1 < 0) {
    currentSuggest.value = focusTargetRefs.value.length - 1;
  } else {
    currentSuggest.value -= 1;
  }

  focusSuggest(currentSuggest.value);
});
</script>

<template>
  <form action="/sekou/keyword.php" @submit="onSubmit">
    <div ref="searchRef" class="flex relative mb-4">
      <input
        class="pl-4 w-full border border-black rounded-l py-2"
        type="text"
        name="kw"
        placeholder="施工例を検索"
        aria-label="Search"
        v-model="kw"
        autocomplete="off"
      />
      <ul
        ref="suggestRef"
        v-if="suggest.length"
        class="absolute w-full left-0 top-full z-20 bg-white border rounded drop-shadow-lg"
      >
        <li class="text-xs px-2 py-1">もしかして…</li>

        <li v-for="word in suggest" :key="word">
          <NuxtLink
            ref="focusTargetRefs"
            class="flex items-center text-sm w-full px-4 py-2 hover:bg-gray-100 focus:bg-blue-100 relative cursor-pointer"
            :to="`/sekou/keyword.php?kw=${word}`"
          >
            <Icon class="mr-2" name="mdi:magnify" />
            <span>{{ word }}</span>
          </NuxtLink>
        </li>
      </ul>

      <button
        class="whitespace-nowrap px-8 py-2 rounded-r block bg-famitei-green text-white"
      >
        検索
      </button>
    </div>
    <div class="gap-2 border relative rounded p-4">
      <p class="absolute -top-2 left-2 text-sm bg-white px-2">
        人気のキーワード
      </p>

      <ul class="flex w-full items-center gap-2 flex-wrap relative">
        <li
          v-for="word in popularWords"
          class="border shrink-0 border-black bg-white rounded-lg text-sm shadow hover:bg-famitei-green hover:text-white"
        >
          <NuxtLink
            :to="`/sekou/keyword.php?kw=${word.name}`"
            class="w-full flex items-center justify-around p-1"
          >
            <span>{{ word.name }}</span>
          </NuxtLink>
        </li>
      </ul>
    </div>
  </form>
</template>
